<template>
  <div class="policy">
    <TitlePage></TitlePage>
    <CommonTitle v-if="curId <= 4" :text="text" :cont="cont"></CommonTitle>
    <div class="policy-cont">
      <div class="contL">
        <div class="tab-bar" v-if="curId <= 4">
          <span
            @click="tab(index)"
            v-for="(item, index) in items"
            :key="index"
            :class="{ policyactive: index == curId }"
          >
            {{ item.name }}
          </span>
        </div>
        <div class="tab-cont">
          <div>
            <div v-html="term.content"></div>
          </div>
        </div>
      </div>
      <div v-if="fullWidth > 900" class="contR"><User></User></div>
    </div>
  </div>
</template>

<script>
import TitlePage from "../../components/TitlePage";
import CommonTitle from "../../components/CommonTitle";
import User from "../../components/User";
import request from "../../api/request";
export default {
  name: "contact",
  components: {
    TitlePage,
    CommonTitle,
    User,
  },
  data() {
    return {
      curId: 0,
      text: this.$t("政策中心"),
      cont: this.$t("政策中心"),
      fullWidth: 0,
      items: [
        { name: this.$t("服务条款") },
        { name: this.$t("个人信息收集协议") },
        { name: this.$t("隐私条款-收费服务条款") },
        { name: this.$t("手机服务条款") },
        { name: this.$t("青少年保护政策") },
        //{ name: this.$t("2022年7月条款") },
      ],
      term: {},
      termOrder: [1, 2, 3, 6, 8, 9, 4, 7], //admin ID
      //?tab=     0, 1, 2, 3, 4, 5, 6, 7
    };
  },
  mounted() {
    this.handleResize();
    let tab = parseInt(this.$route.query.tab);
    if (tab) this.curId = tab;
    this.getTerm();
  },
  methods: {
    getTerm() {
      let _this = this;
      console.log("getTerm: " + this.termOrder[this.curId]);
      request
        .get("/terms/" + this.termOrder[this.curId], {})
        .then(function (res) {
          if (res.status == 200) {
            _this.term = res.data;
          }
        });
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
    },
    tab(index) {
      this.curId = index;
      this.getTerm();
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    $route() {
      let currentTab = parseInt(this.$route.query.tab);
      this.curId = currentTab;
      this.getTerm(); //Directly request in browser
    },
  },
};
</script>

<style lang="scss">
.policy {
  .policy-cont {
    max-width: 1220px;
    margin: 20px auto;
    min-height: 1000px;
    display: flex;
    @media only screen and (max-width: 900px) {
      margin: 20px;
    }
    .contL {
      width: 80%;
      position: relative;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
      .tab-bar {
        span {
          cursor: pointer;
          font-size: 18px;
          display: inline-block;
          margin-right: 50px;
          padding-bottom: 10px;
        }
      }
      .tab-cont {
        margin-top: 20px;
      }
      .spin {
        position: absolute;
        top: 30%;
        left: 50%;
      }
    }
  }
  .policyactive {
    color: #3e46fe;
    border-bottom: 1px solid #3e46fe;
  }
}
</style>
